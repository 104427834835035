// Import React and useState
import React, { useState } from 'react';
// Import CSS for styling
import '../styles/Footer.css';
// Import useTranslation hook from react-i18next
import { useTranslation } from 'react-i18next';

function Footer() {
  // Initialize emailVisible state
  const [emailVisible, setEmailVisible] = useState(false);
  // Define user and domain for email
  const user = 'info';
  const domain = 'constantinsfamily.ch';
  const email = `${user}@${domain}`;

  // Handle email click to reveal email
  const handleEmailClick = (event) => {
    event.preventDefault();
    setEmailVisible(true);
  };

  // Use useTranslation hook
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <section className="links">
          <h3>{t('quickLinks')}</h3>
          <ul>
            <li><a href="/">{t('home')}</a></li>
            <li><a href="/apartments">{t('apartments')}</a></li>
            <li><a href="/contact">{t('contact')}</a></li>
          </ul>
        </section>
        <section className="about">
          <h3>{t('aboutUs')}</h3>
          <p>{t('aboutUsText')}</p>
        </section>
        <section className="contact">
          <h3>{t('contactUs')}</h3>
          <p>Email: {emailVisible ? (
            <a href={`mailto:${email}`} id="email-link">{email}</a>
          ) : (
            <a href="#reveal-email" onClick={handleEmailClick} id="email-link">{t('clickHere')}</a>
          )}</p>
        </section>
      </div>
      <div className="footer-bottom">
        <p>{t('copyright')}</p>
      </div>
    </footer>
  );
}

export default Footer;