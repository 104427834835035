import React, { useState, useEffect } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language); // Update state to force re-render
    localStorage.setItem('language', language);
  };

  const getCurrentLanguageFlag = () => {
    switch (language) {
      case 'en':
        return <ReactCountryFlag countryCode="GB" svg style={{ width: '2em', height: '1.5em' }} />;
      case 'fr':
        return <ReactCountryFlag countryCode="FR" svg style={{ width: '2em', height: '1.5em' }} />;
      default:
        return <ReactCountryFlag countryCode="FR" svg style={{ width: '2em', height: '1.5em' }} />;
    }
  };

  useEffect(() => {
    // Listen for language change events
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup listener on unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  return (
    <NavDropdown title={getCurrentLanguageFlag()} id="basic-nav-dropdown">
      <NavDropdown.Item onClick={() => changeLanguage('fr')}>
        <ReactCountryFlag countryCode="FR" svg style={{ width: '2em', height: '2em', marginRight: '0.5em' }} />
        Français
      </NavDropdown.Item>
      <NavDropdown.Item onClick={() => changeLanguage('en')}>
        <ReactCountryFlag countryCode="GB" svg style={{ width: '2em', height: '2em', marginRight: '0.5em' }} />
        English
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default LanguageSelector;