import UrbanNestImage from '../assets/apartments/urbannest.jpg';
import SecretGardenImage from '../assets/apartments/secretgarden.jpg';
import CuteFactoryImage from '../assets/apartments/cutefactory.webp';
import GrandpasHomeImage from '../assets/apartments/grandpashome.jpg';
import PrettyPlanetImage from '../assets/apartments/prettyplanet.jpg';
import LittleOasisImage from '../assets/apartments/littleoasis.jpg';
import PlaceToBeImage from '../assets/apartments/placetobe.webp';
import { useTranslation } from 'react-i18next';

const useApartmentsData = () => {
  const { t } = useTranslation();

  const apartments = [
    { id: 1, title: 'Urban Nest', description: t('descriptions.studio'), imageUrl: UrbanNestImage, airbnbId: 45543119 },
    { id: 2, title: 'Secret Garden', description: t('descriptions.studio'), imageUrl: SecretGardenImage, airbnbId: 45537099 },
    { id: 3, title: 'Cute Factory', description: t('descriptions.studio'), imageUrl: CuteFactoryImage, airbnbId: 43602522 },
    { id: 4, title: 'Pretty Planet', description: t('descriptions.studio'), imageUrl: PrettyPlanetImage, airbnbId: 46296518 },
    { id: 5, title: 'Little Oasis', description: t('descriptions.studio'), imageUrl: LittleOasisImage, airbnbId: 26678572 },
    { id: 6, title: "Grandpa's Home", description: t('descriptions.apartment'), imageUrl: GrandpasHomeImage, airbnbId: 33450154 },
    { id: 7, title: 'Place To Be', description: t('descriptions.apartment'), imageUrl: PlaceToBeImage, airbnbId: 53334330 },
  ];

  return apartments;
};

export default useApartmentsData;