import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import '../styles/NavbarComponent.css';
import Logo from '../assets/logo.jpg';
import LanguageSelector from './LanguageSelector'; // Import the new LanguageSelector component

function NavbarComponent() {
  const { t } = useTranslation();

  return (
    <Navbar bg="light" expand="lg" className="navbar-pastel-green">
      <LinkContainer to="/">
        <Navbar.Brand>
          <img
            src={Logo}
            width="60"
            height="60"
            className="d-inline-block align-top"
            alt={t('navbar.logoAlt')}
          />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer to="/">
            <Nav.Link>{t('navbar.home')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/apartments">
            <Nav.Link>{t('navbar.apartments')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/contact">
            <Nav.Link>{t('navbar.contact')}</Nav.Link>
          </LinkContainer>
        </Nav>
        <Nav className="ml-auto">
          <LanguageSelector />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;