import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend) // if you're using i18next-http-backend
  .use(LanguageDetector) // if you're using a language detector
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true, // Enable debug mode to see detailed logs
    lng: 'fr', // Set the default language
    fallbackLng: 'fr', // Specify a fallback language
    detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}.json', // Path to your translation files
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

const savedLanguage = localStorage.getItem('language');
if (savedLanguage) {
  i18n.changeLanguage(savedLanguage);
}

export default i18n;