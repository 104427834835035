import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/PrivacyPolicyPage.css'; // Import the CSS file

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <section className="privacy-section">
        <h1>{t('privacyPolicyTitle')}</h1>
        <p>{t('privacyPolicyIntro')}</p>
        <h2>{t('dataCollectionTitle')}</h2>
        <p>{t('dataCollectionIntro')}</p>
        <ul>
          <li><strong>{t('dataCollectionName')}</strong></li>
          <li><strong>{t('dataCollectionEmail')}</strong></li>
          <li><strong>{t('dataCollectionMessage')}</strong></li>
        </ul>
        <h2>{t('purposeOfDataCollectionTitle')}</h2>
        <p>{t('purposeOfDataCollectionIntro')}</p>
        <ul>
          <li>{t('purposeOfDataCollectionRespond')}</li>
          <li>{t('purposeOfDataCollectionSupport')}</li>
        </ul>
        <h2>{t('dataUsageTitle')}</h2>
        <p>{t('dataUsageIntro')}</p>
        <h2>{t('dataSharingTitle')}</h2>
        <p>{t('dataSharingIntro')}</p>
        <h2>{t('dataSecurityTitle')}</h2>
        <p>{t('dataSecurityIntro')}</p>
        <h2>{t('userRightsTitle')}</h2>
        <p>{t('userRightsIntro')}</p>
        <ul>
          <li>{t('userRightsAccess')}</li>
          <li>{t('userRightsCorrection')}</li>
          <li>{t('userRightsDeletion')}</li>
        </ul>
        <p>{t('userRightsContact')}</p>
        <h2>{t('cookiesAndTrackingTitle')}</h2>
        <p>{t('cookiesAndTrackingIntro')}</p>
        <p>{t('cookiesAndTrackingConsent')}</p>
        <p>{t('privacyPolicyContact')}</p>
        <p>{t('privacyPolicyThanks')}</p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;