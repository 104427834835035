import React from 'react';
import '../styles/ContactPage.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ContactPage() {
  const { t } = useTranslation();
  const [emailVisible, setEmailVisible] = useState(false);

  const user = 'info';
  const domain = 'constantinsfamily.ch';
  const email = `${user}@${domain}`;

  const handleEmailClick = (event) => {
    event.preventDefault();
    setEmailVisible(true);
  };

  return (
    <div className="container">
      <h1>{t('contactUs')}</h1>
      <form>
        <label>{t('nameLabel')}</label>
        <input type="text" name="name" required />
        <label>{t('emailLabel')}</label>
        <input type="email" name="email" required />
        <label>{t('messageLabel')}</label>
        <textarea name="message" required></textarea>
        <div className="privacy-link">
          <p>
            {t('privacyStatement1')}
            <a href="/privacy-policy" target="_blank">{t('privacyStatement2')}</a>
            {t('privacyStatement3')}
          </p>
        </div>
        <button type="submit">{t('sendButton')}</button>
      </form>
      <div className="contact-info">
        <p dangerouslySetInnerHTML={{ __html: t('address') }}></p>
        <p>{t('email')} {emailVisible ? (
            <a href={`mailto:${email}`} id="email-link">{email}</a>
          ) : (
            <a href="#reveal-email" onClick={handleEmailClick} id="email-link">{t('clickHere')}</a>
          )}</p>
      </div>
    </div>
  );
}

export default ContactPage;