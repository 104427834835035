import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import './assets/logo.jpg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import ApartmentsPage from './pages/ApartmentsPage';
import NavbarComponent from './components/NavbarComponent';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import './i18n';
import './styles/global.css';


function App() {
  return (
    <Router>
      <div>
        {/* Navbar */}
        <NavbarComponent />
        {/* Routes */}
        <Routes>
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/apartments" element={<ApartmentsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;