import React, { useRef } from 'react';
import ApartmentCard from './ApartmentCard';
import '../styles/FeaturedApartmentsWithArrows.css';


import { useTranslation } from 'react-i18next';

const FeaturedApartmentsWithArrows = ({ apartments }) => {

  const { t } = useTranslation();
  
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -350, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 350, behavior: 'smooth' });
    }
  };

  return (
    <section className="featured-apartments">
      <h2>{t('featuredRentals')}</h2>
      <button onClick={scrollLeft} className="scroll-left">{'<'}</button>
      <div className="apartments-scroll-container" ref={scrollContainerRef}>
        {apartments.map((apartment, index) => (
          <ApartmentCard key={index} apartment={apartment} />
        ))}
      </div>
      <button onClick={scrollRight} className="scroll-right">{'>'}</button>
    </section>
  );
};

export default FeaturedApartmentsWithArrows;