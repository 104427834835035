import React from 'react';
import '../styles/ApartmentCard.css';


function airbnbLink(airbnbId) {
    return `https://airbnb.ch/rooms/${airbnbId}`;
}

function ApartmentCard({ apartment }) {
    return (
      <div key={apartment.id} className="col-sm-12 col-md-6 col-lg-3 mb-3">
        <a href={airbnbLink(apartment.airbnbId)} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className="card">
            <img src={apartment.imageUrl} className="card-img-top card-img-custom" alt={apartment.title} />
            <div className="card-body">
              <h5 className="card-title">{apartment.title}</h5>
              <p className="card-text">{apartment.description}</p>
            </div>
          </div>
        </a>
      </div>
    );
}


export default ApartmentCard;