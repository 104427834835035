import React from 'react';
import '../styles/ApartmentsPage.css';
import ApartmentCard from '../components/ApartmentCard';
import useApartmentsData from '../data/ApartmentsData';

function ApartmentsPage() {
  return (
    <div className="container-fluid">
      <div className="row custom-row-padding"> {/* Added custom class for padding */}
        {useApartmentsData().map((apartment) => (
          <ApartmentCard key={apartment.id} apartment={apartment} />
        ))}
      </div>
    </div>
  );
}

export default ApartmentsPage;