import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';
import logo from '../assets/logo.jpg';
//import FeaturedApartments from '../components/FeaturedAppartments';
import FeaturedApartmentsWithArrows from '../components/FeaturedApartmentsWithArrows'; // Adjust the import path as necessary
import useApartmentsData from '../data/ApartmentsData';
import { useTranslation } from 'react-i18next';


function HomePage() {
  const { t } = useTranslation(); // Use the t function to translate the text
  
  return (
    <div className="home-page">
      <section className="logo-section">
        <img src={logo} alt="Constantin's Family" />
      </section>

      <section className="welcome-section">
        <h1>{t('welcomeTitle')}</h1>
        <p>{t('welcomeText')}</p>
      </section>

      <FeaturedApartmentsWithArrows apartments={useApartmentsData()} />

      <section className="cta-section">
        <h2>{t('ctaTitle')}</h2>
        <p>{t('ctaText')}</p>
        <div className="row">
          <div className="col-12 col-md-6">
            <Link to="/contact" className="cta-button">{t('ctaButton')}</Link>
          </div>
          <div className="col-12 col-md-6">
            <Link to="https://fr.airbnb.ch/users/show/126723788" className="airbnb-button">{t('airbnbButton')}</Link>
          </div>
        </div>
      </section>

      <section className="testimonials-section">
        <h2>{t('testimonialsTitle')}</h2>
        <div className="testimonials">
          <blockquote>
            {t('testimonial1')}
            <cite>{t('testimonial1Cite')}</cite>
          </blockquote>
          <blockquote>
            {t('testimonial2')}
            <cite>{t('testimonial2Cite')}</cite>
          </blockquote>
          <blockquote>
            {t('testimonial3')}
            <cite>{t('testimonial3Cite')}</cite>
          </blockquote>
        </div>
      </section>
    </div>
  );
}

export default HomePage;